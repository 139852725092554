import { combineReducers, compose, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import tokenReducer from "./tokenSlice";
import adminSlice from "./AdminSlice";
import thunk from "redux-thunk";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  tokenReducer: tokenReducer,
  adminReducer: adminSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
  devTools: true,
});

export const persistor = persistStore(store);