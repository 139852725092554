import React from "react";
import SidebarLinkGroup from "./SlidebarLinkGroup";
import { NavLink, useLocation } from "react-router-dom";

const SidebarMenu = ({
  curPathName,
  shortPathName,
  sidebarExpanded,
  setSidebarExpanded,
  SideBarIcon,
  menuName,
}) => {
  const { pathname } = useLocation();
  return (
    <SidebarLinkGroup
      activecondition={
        pathname === curPathName || pathname.includes(shortPathName)
      }
    >
      {(handleClick, open) => {
        return (
          <React.Fragment>
            <div
              className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                (pathname === curPathName ||
                  pathname.includes(shortPathName)) &&
                "hover:text-slate-200"
              }`}
              onClick={(e) => {
                e.preventDefault();
                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {SideBarIcon}
                  <NavLink
                    end
                    to={curPathName}
                    className={
                      pathname === curPathName
                        ? "block truncate transition duration-150 text-slate-400"
                        : "block truncate transition duration-150 text-slate-400 hover:text-slate-200"
                    }
                  >
                    <span className="ml-3 text-sm font-medium duration-200 me lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      {menuName}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </SidebarLinkGroup>
  );
};

export default SidebarMenu;
