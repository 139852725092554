import * as Yup from "yup";

export default Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  location: Yup.string().required("Location is required"),
  totalExperience: Yup.number()
    .min(1, "Experience must be greater than 0")
    .required("Total experience is required"),
  "reson-for-job-change": Yup.string().required(
    "Reason for job change is required"
  ),
  "last-date-of-current-job": Yup.date().required(
    "Last date of current job is required"
  ),
  images: Yup.array()
    .min(1, "At least one image is required")
    .of(
      Yup.mixed().test(
        "fileType",
        "Only image files are allowed",
        (file) => file && file.type && file.type.startsWith("image/")
      )
    )
    .required("Images are required"),
});
