import React from "react";

const BlogIcon = ({ active }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7127 13.606L15.1636 13.9713C14.817 14.0531 14.4606 13.9521 14.2085 13.7046C13.9564 13.457 13.857 13.1013 13.9394 12.7577L14.3079 11.222C14.3515 11.0393 14.4436 10.8759 14.577 10.7413L18.3442 7.00895V6.29276C18.3442 5.06947 17.4909 4.0697 16.4461 4.0697H1.89818C0.853333 4.0697 0 5.06947 0 6.29276V13.5508C0 14.774 0.853333 15.7738 1.89818 15.7738H4.30303C4.46303 15.7738 4.60121 15.8507 4.70061 15.9973C4.80242 16.1439 4.83636 16.317 4.80242 16.4996L4.28121 19.273C4.23273 19.535 4.3297 19.7898 4.52849 19.9219C4.72727 20.0517 4.96727 20.0181 5.13212 19.833L8.17455 16.4467C8.57939 15.9973 9.05697 15.7714 9.61697 15.7714H16.4509C17.4933 15.7714 18.3491 14.7716 18.3491 13.5484V12.1953L17.2 13.3369C17.0618 13.4715 16.897 13.5628 16.7127 13.606ZM3.96364 7.81646H14.383C14.5964 7.81646 14.7709 7.9895 14.7709 8.20099C14.7709 8.41248 14.5964 8.58552 14.383 8.58552H3.96364C3.7503 8.58552 3.57576 8.41248 3.57576 8.20099C3.57576 7.9895 3.7503 7.81646 3.96364 7.81646ZM9.2703 12.0319H3.96364C3.7503 12.0319 3.57576 11.8588 3.57576 11.6473C3.57576 11.4358 3.74788 11.2652 3.96364 11.2652H9.2703C9.48364 11.2652 9.65576 11.4358 9.65576 11.6473C9.65818 11.8612 9.48364 12.0319 9.2703 12.0319ZM14.6933 12.9355L14.9745 11.7747L16.16 12.9475L14.9867 13.2263C14.9018 13.2455 14.8194 13.2215 14.7588 13.1614C14.6982 13.1013 14.6739 13.0196 14.6933 12.9355ZM23.8182 5.68953L23.3309 6.17259L21.8085 4.66331L22.2958 4.18025C22.5406 3.93992 22.9406 3.93992 23.183 4.18025L23.8182 4.80992C24.0606 5.05025 24.0606 5.44679 23.8182 5.68953ZM21.2606 5.20646L22.783 6.71574L16.8727 12.5726L15.3503 11.0633L21.2606 5.20646Z"
          fill={active ? "#a5b4fc" : "#475569"}
        />
      </svg>
    </>
  );
};

export default BlogIcon;
