import React from "react";

const UserRoleIcon = ({ active }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7647 11.4707C15.8223 11.4707 14.9011 11.7501 14.1176 12.2737C13.334 12.7973 12.7233 13.5414 12.3627 14.412C12.0021 15.2827 11.9077 16.2407 12.0916 17.165C12.2754 18.0892 12.7292 18.9382 13.3956 19.6046C14.0619 20.2709 14.9109 20.7247 15.8352 20.9086C16.7594 21.0924 17.7174 20.998 18.5881 20.6374C19.4587 20.2768 20.2029 19.6661 20.7264 18.8825C21.25 18.099 21.5294 17.1778 21.5294 16.2354C21.5228 14.9738 21.0187 13.7657 20.1266 12.8735C19.2345 11.9814 18.0264 11.4773 16.7647 11.4707ZM17.6006 16.6534C17.471 16.6502 17.3432 16.6218 17.2245 16.5698L15.4272 18.367C15.3265 18.4668 15.1927 18.5262 15.0511 18.5342C14.9793 18.5411 14.9069 18.5294 14.841 18.5001C14.7751 18.4708 14.7179 18.4249 14.6749 18.367C14.5741 18.2593 14.5181 18.1174 14.5181 17.9699C14.5181 17.8225 14.5741 17.6805 14.6749 17.5729L16.4721 15.7757C16.4245 15.6555 16.3963 15.5285 16.3885 15.3995C16.3695 15.2002 16.3919 14.9991 16.4543 14.8089C16.5168 14.6187 16.6179 14.4435 16.7514 14.2943C16.8849 14.1451 17.0479 14.0252 17.23 13.9421C17.4121 13.8589 17.6094 13.8144 17.8096 13.8113C17.9392 13.8144 18.067 13.8428 18.1858 13.8949C18.2694 13.8949 18.2693 13.9784 18.2276 14.0202L17.3916 14.8144C17.3723 14.8241 17.356 14.839 17.3447 14.8575C17.3333 14.8759 17.3273 14.8972 17.3273 14.9188C17.3273 14.9405 17.3333 14.9617 17.3447 14.9802C17.356 14.9986 17.3723 15.0136 17.3916 15.0233L17.935 15.5667C17.9498 15.5858 17.9688 15.6012 17.9906 15.6119C18.0123 15.6225 18.0362 15.628 18.0604 15.628C18.0846 15.628 18.1084 15.6225 18.1302 15.6119C18.1519 15.6012 18.1709 15.5858 18.1858 15.5667L18.9799 14.7726C19.0217 14.7308 19.1471 14.7308 19.1471 14.8144C19.1905 14.9358 19.2185 15.0621 19.2307 15.1905C19.2275 15.3962 19.1819 15.5989 19.0966 15.7861C19.0114 15.9733 18.8884 16.1408 18.7353 16.2782C18.5822 16.4155 18.4024 16.5198 18.2072 16.5843C18.0119 16.6489 17.8054 16.6724 17.6006 16.6534Z"
          className={`fill-current ${
            active ? "text-indigo-500" : "text-slate-600"
          }`}
        />
        <path
          d="M10.4112 12.5294C13.0427 12.5294 15.1759 10.3962 15.1759 7.76471C15.1759 5.13323 13.0427 3 10.4112 3C7.77972 3 5.64648 5.13323 5.64648 7.76471C5.64648 10.3962 7.77972 12.5294 10.4112 12.5294Z"
          fill={active ? "#a5b4fc" : "#475569"}
        />
        <path
          d="M11.4761 20.9621C12.3451 20.9621 11.8711 20.395 11.8711 20.395C10.999 19.3552 10.5251 18.0611 10.5282 16.7277C10.524 15.892 10.7128 15.0657 11.0811 14.308C11.0977 14.2648 11.1248 14.2259 11.1601 14.1946C11.4366 13.6653 10.8837 13.6275 10.8837 13.6275C10.6349 13.5961 10.384 13.5834 10.1332 13.5897C8.27361 13.5966 6.47818 14.2412 5.07241 15.4064C3.66665 16.5717 2.74363 18.1805 2.4707 19.9413C2.4707 20.3194 2.5892 20.9999 3.81362 20.9999H11.3576C11.4366 20.9621 11.4366 20.9621 11.4761 20.9621Z"
          fill={active ? "#a5b4fc" : "#475569"}
        />
      </svg>
    </>
  );
};

export default UserRoleIcon;
