import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../helper/api";
import { tokenSlice } from "../redux/tokenSlice";
import { adminSlice } from "../redux/AdminSlice";

const AdminRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionToken = sessionStorage.getItem("token");
  const admin = useSelector((state) => state.adminReducer.data);
  const token = useSelector((state) => state.tokenReducer.token);

  const checkUserToken = () => {
    sessionToken !== token && navigate("/admin-login");
  };

  const getCurrentAuth = () => {
    api
      .getWithToken(
        `${process.env.REACT_APP_API}/getsingleauth?id=${admin._id}`
      )
      .then((res) => {
        sessionStorage.setItem("token", res.data.data.token);
        dispatch(tokenSlice(res.data.data.token));
        dispatch(adminSlice(res.data.data));
      });
  };

  useEffect(() => {
    checkUserToken();
    // getCurrentAuth();
  }, []);

  return sessionToken === token && children;
};

export default AdminRoute;
