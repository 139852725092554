import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    admin: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { admin } = adminSlice.actions;

export default adminSlice;
