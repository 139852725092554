import React, { useState } from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../helper/api";
import { sideBarMenus } from "../../../common/Data/SidebarData";

const EditModal = ({
  setMessageData,
  open,
  setOpen,
  callBack,
  roleData,
  setRoleData,
}) => {
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const { data } = await api.putWithToken(
        `${process.env.REACT_APP_API}/updaterole?id=${roleData._id}`,
        roleData
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        handleClose();
        callBack();
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setRoleData({
      name: "",
      permissions: [],
    });
  };
  const handleCheck = (value) => {
    const isExist = roleData.permissions.find((res) => res.module === value);
    if (isExist) {
      const updatedData = roleData.permissions.filter(
        (res) => res.module !== value
      );
      setRoleData({ ...roleData, permissions: updatedData });
    } else {
      const data = {
        module: value,
        actions: {
          create: true,
          update: true,
          delete: true,
          view: true,
        },
      };
      setRoleData({
        ...roleData,
        permissions: [...roleData.permissions, data],
      });
    }
  };
  const handleSubMenuClick = ({ target }) => {
    const currentData = roleData.permissions.map((val) => {
      if (val.module === target.id) {
        const sendingData = {
          ...val,
          actions: { ...val.actions, [target.name]: !val.actions[target.name] },
        };
        return sendingData;
      } else {
        return val;
      }
    });
    setRoleData({
      ...roleData,
      permissions: currentData,
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
      >
        <>
          <div>
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 h-[80%] overflow-y-auto md:p-10 w-[90%] md:w-[800px]">
              <div className="absolute top-2 right-2">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mt-5">
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-2">
                      <label
                        htmlFor="name"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Name
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter Role Name"
                        value={roleData.name}
                        onChange={({ target }) =>
                          setRoleData({ ...roleData, name: target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-span-2 space-y-2 max-h-[450px] overflow-auto">
                      <table className=" w-full table-auto relative">
                        <thead className="sticky top-0 font-semibold uppercase text-slate-400 bg-slate-50">
                          <tr>
                            <th className=" p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left"></div>
                            </th>
                            <th className="p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left">
                                Name
                              </div>
                            </th>
                            <th className="p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left">
                                Create
                              </div>
                            </th>
                            <th className="p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left">
                                Read
                              </div>
                            </th>
                            <th className="p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left">
                                Update
                              </div>
                            </th>
                            <th className="p-2 whitespace-nowrap">
                              <div className="text-[14px] font-semibold text-left">
                                Delete
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sideBarMenus.map((res, i) => {
                            const currentData = roleData.permissions.find(
                              (val) => val.module === res
                            );
                            return (
                              <tr
                                key={i}
                                className="border-b-2 hover:bg-slate-50"
                              >
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <input
                                      id={res}
                                      name="name"
                                      type="checkbox"
                                      className="h-[15px] w-[15px]"
                                      placeholder="Enter Role Name"
                                      checked={currentData ? true : false}
                                      onChange={() => handleCheck(res)}
                                    />
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <div className="flex items-center justify-start space-x-2">
                                      <label
                                        htmlFor={res}
                                        className="block text-sm font-medium text-gray-900 cursor-pointer select-none"
                                      >
                                        {res}
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <input
                                      id={res}
                                      name="create"
                                      type="checkbox"
                                      placeholder="Enter Role Name"
                                      className="h-[15px] w-[15px]"
                                      checked={
                                        currentData &&
                                        currentData.actions.create
                                          ? true
                                          : false
                                      }
                                      onChange={handleSubMenuClick}
                                      disabled={currentData ? false : true}
                                    />
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <input
                                      id={res}
                                      name="view"
                                      type="checkbox"
                                      placeholder="Enter Role Name"
                                      className="h-[15px] w-[15px]"
                                      checked={
                                        currentData && currentData.actions.view
                                          ? true
                                          : false
                                      }
                                      onChange={handleSubMenuClick}
                                      disabled={currentData ? false : true}
                                    />
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <input
                                      id={res}
                                      name="update"
                                      type="checkbox"
                                      placeholder="Enter Role Name"
                                      className="h-[15px] w-[15px]"
                                      checked={
                                        currentData &&
                                        currentData.actions.update
                                          ? true
                                          : false
                                      }
                                      onChange={handleSubMenuClick}
                                      disabled={currentData ? false : true}
                                    />
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-[20px] text-left text-blue">
                                    <input
                                      id={res}
                                      name="delete"
                                      type="checkbox"
                                      placeholder="Enter Role Name"
                                      className="h-[15px] w-[15px]"
                                      checked={
                                        currentData &&
                                        currentData.actions.delete
                                          ? true
                                          : false
                                      }
                                      onChange={handleSubMenuClick}
                                      disabled={currentData ? false : true}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default EditModal;
