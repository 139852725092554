import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import Header from "../../components/AdminPanelComponents/Header";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RoleTable from "./components/RoleTable";
import AddModal from "./components/AddModal";
import SnackBar from "../../components/SnackBars/SnackBar";
import api from "../../helper/api";
import EditModal from "./components/EditModal";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";

const RoleManagement = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);

  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [messageData, setMessageData] = useState({
    messageText: "",
    open: false,
    type: "info",
  });
  const [page, setPage] = useState(1);
  const [roleData, setRoleData] = useState([]);
  const [totalData, setTotalData] = useState(1);
  const [roleSingleData, setRoleSingleData] = useState({
    name: "",
    permissions: [],
  });

  const getAllRoleData = async () => {
    try {
      const { data } = await api.getWithToken(
        `${process.env.REACT_APP_API}/getallrole?page=${page}&limit=10`
      );
      if (data.success) {
        setRoleData(data.data);
        setTotalData(data.totalPages);
      } else {
        setRoleData([]);
      }
    } catch (error) {
      console.log(error);
      setRoleData([]);
    }
  };

  const handleRemoveData = async (roleId) => {
    try {
      const { data } = await api.deleteWithToken(
        `${process.env.REACT_APP_API}/deleterole?id=${roleId}`
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        getAllRoleData();
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  const askRemoveData = (roleId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You really want to remove this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1565CD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveData(roleId);
      }
    });
  };

  const handleUpdate = (data) => {
    setRoleSingleData(data);
    setUpdateModal(true);
  };

  useEffect(() => {
    getAllRoleData();
  }, [page]);

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.roleManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className=" bg-white container mx-auto">
            <div className="p-5 w-full">
              <header className="flex justify-between relative py-4 border-b border-slate-100">
                <h2 className="text-sm  font-semibold text-slate-800">
                  All Roles
                </h2>
                <div>
                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={() => setAddModal(true)}
                    disabled={!permission.actions.create}
                  >
                    Add Roles
                  </Button>
                </div>
              </header>
              <RoleTable
                roleData={roleData}
                page={page}
                setPage={setPage}
                totalData={totalData}
                handleUpdate={handleUpdate}
                handleRemove={askRemoveData}
                permission={permission}
              />
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        open={messageData.open}
        setOpen={(val) =>
          setMessageData({
            messageText: "",
            type: "info",
            open: val,
          })
        }
        text={messageData.messageText}
        type={messageData.type}
      />
      <AddModal
        setMessageData={setMessageData}
        open={addModal}
        setOpen={setAddModal}
        callBack={getAllRoleData}
      />
      <EditModal
        setMessageData={setMessageData}
        open={updateModal}
        setOpen={setUpdateModal}
        callBack={getAllRoleData}
        roleData={roleSingleData}
        setRoleData={setRoleSingleData}
      />
    </>
  );
};

export default RoleManagement;
