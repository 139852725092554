import React, { useEffect, useState } from "react";
import Header from "../../components/AdminPanelComponents/Header";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import api from "../../helper/api";
import { CONTACT } from "../../helper/endpoints";
import { IconButton, Pagination } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";

const Contactmanagement = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);

  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const getContact = async () => {
    api
      .getWithToken(`${process.env.REACT_APP_API + CONTACT + `?page=${page}`}`)
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setContactData(res.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getContact();
  }, [page]);

  const hendelDelete = (id) => {
    api
      .deleteWithToken(`${process.env.REACT_APP_API + CONTACT + `/${id}`}`)
      .then((res) => {
        if (contactData.length === 1) {
          setPage(page - 1);
        }
        getContact();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.contactManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className=" bg-white container mx-auto">
          <div className="p-5 w-full">
            <header className="relative py-4 border-b border-slate-100">
              <h2 className="text-sm  font-semibold text-slate-800">
                All Contact
              </h2>
            </header>
            <div className="overflow-x-auto">
              <table className=" w-full table-auto">
                {/* Table header */}
                <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className=" p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Name
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Email
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Phone No.
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Message
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                {contactData?.map((data) => (
                  <tbody key={data._id}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px]  text-blue">
                          {data.name}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.phone}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.message}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => {
                              hendelDelete(data._id);
                            }}
                            disabled={!permission.actions.delete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                pages={totalPage}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Contactmanagement;
