import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import Header from "../../components/AdminPanelComponents/Header";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddUserModal from "./Components/AddUserModal";
import SnackBar from "../../components/SnackBars/SnackBar";
import api from "../../helper/api";
import UserTable from "./Components/UserTable";
import Swal from "sweetalert2";
import UpdateUserModal from "./Components/UpdateUserModal";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";

const serverUrl = process.env.REACT_APP_UPLOADS;

const UserManagemnet = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);
  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [userData, setUserData] = useState([]);
  const [messageData, setMessageData] = useState({
    messageText: "",
    open: false,
    type: "info",
  });
  const [singleUserData, setSingleUserData] = useState({
    name: "",
    email: "",
    password: "",
    role_ID: "",
    position: "",
  });
  const [userImage, setUserImage] = useState({
    img: null,
    imgName: "",
    imgUrl: "",
  });

  const getAllUsers = async () => {
    try {
      const { data } = await api.getWithToken(
        `${process.env.REACT_APP_API}/getalluser?limit=10&page=${page}`
      );
      if (data.success) {
        setUserData(data.data);
        setTotalData(data.totalPages);
      } else {
        setUserData([]);
      }
    } catch (error) {
      setUserData([]);
      console.log(error);
    }
  };

  const handleRemoveData = async (userId) => {
    try {
      const { data } = await api.deleteWithToken(
        `${process.env.REACT_APP_API}/deleteuser?id=${userId}`
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        getAllUsers();
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  const askRemoveData = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You really want to remove this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1565CD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveData(userId);
      }
    });
  };

  const handleEdit = (data) => {
    setEditModal(true);
    setSingleUserData(data);

    setUserImage({
      ...userImage,
      imgUrl: `${serverUrl}${data.img}`,
      imgName: data.img,
    });
  };

  useEffect(() => {
    getAllUsers();
  }, [page]);

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.userManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className=" bg-white container mx-auto">
            <div className="p-5 w-full">
              <header className="flex justify-between relative py-4 border-b border-slate-100">
                <h2 className="text-sm  font-semibold text-slate-800">
                  All Users
                </h2>
                <div>
                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={() => setAddModal(true)}
                    disabled={!permission.actions.create}
                  >
                    Add Users
                  </Button>
                </div>
              </header>
              <UserTable
                userData={userData}
                page={page}
                setPage={setPage}
                totalData={totalData}
                handleDelete={askRemoveData}
                handleEdit={handleEdit}
                permission={permission}
              />
            </div>
          </div>
        </div>
        <AddUserModal
          open={addModal}
          setOpen={setAddModal}
          setMessageData={setMessageData}
          callback={getAllUsers}
        />
        <UpdateUserModal
          open={editModal}
          setOpen={setEditModal}
          setMessageData={setMessageData}
          userData={singleUserData}
          setUserData={setSingleUserData}
          userImage={userImage}
          setUserImage={setUserImage}
          callback={getAllUsers}
        />
        <SnackBar
          open={messageData.open}
          setOpen={(val) =>
            setMessageData({
              messageText: "",
              type: "info",
              open: val,
            })
          }
          text={messageData.messageText}
          type={messageData.type}
        />
      </div>
    </>
  );
};

export default UserManagemnet;
