import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SlidebarLinkGroup";
import SidebarMenu from "./SidebarMenu";
import QuoteIcon from "../../common/Icons/QuoteIcon";
import ContactIcon from "../../common/Icons/ContactIcon";
import JobIcon from "../../common/Icons/JobIcon";
import HireIcon from "../../common/Icons/HireIcon";
import PortfolioIcon from "../../common/Icons/PortfolioIcon";
import SmartImagesIcon from "../../common/Icons/SmartImagesIcon";
import ServiceIcon from "../../common/Icons/ServiceIcon";
import BlogIcon from "../../common/Icons/BlogIcon";
import UserRoleIcon from "../../common/Icons/UserRoleIcon";
import UserIcon from "../../common/Icons/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import { sideBarData } from "../../common/Data/SidebarData";
import { token } from "../../redux/tokenSlice";
import { admin } from "../../redux/AdminSlice";
import api from "../../helper/api";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const trigger = useRef(null);
  const sidebar = useRef(null);
  const dispatch = useDispatch();
  const adminDetails = useSelector((state) => state.adminReducer.data);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );
  const [permissions, setPermissions] = useState([]);

  const getCurrentAuth = () => {
    api
      .getWithToken(
        `${process.env.REACT_APP_API}/getsingleauth?id=${adminDetails._id}`
      )
      .then((res) => {
        sessionStorage.setItem("token", res.data.data.token);
        dispatch(token(res.data.data.token));
        dispatch(admin(res.data.data));
      });
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      setPermissions(adminDetails.role_ID.permissions.map((val) => val.module));
    }
  }, [adminDetails.role_ID]);
  useEffect(() => {
    getCurrentAuth();
  }, []);
  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>
      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto scrollbar-hide w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <svg width="32" height="32" viewBox="0 0 32 32">
              <defs>
                <linearGradient
                  x1="28.538%"
                  y1="20.229%"
                  x2="100%"
                  y2="108.156%"
                  id="logo-a"
                >
                  <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                  <stop stopColor="#A5B4FC" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="88.638%"
                  y1="29.267%"
                  x2="22.42%"
                  y2="100%"
                  id="logo-b"
                >
                  <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                  <stop stopColor="#38BDF8" offset="100%" />
                </linearGradient>
              </defs>
              <rect fill="#6366F1" width="32" height="32" rx="16" />
              <path
                d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                fill="#4F46E5"
              />
              <path
                d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                fill="url(#logo-a)"
              />
              <path
                d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                fill="url(#logo-b)"
              />
            </svg>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul>
              {/* Dashboard */}
              {/* <SidebarLinkGroup
                activecondition={
                  pathname === "/admin/smartimages" ||
                  pathname.includes("smartimages")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <div
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${(pathname === "/admin/smartimages" ||
                          pathname.includes("smartimages")) &&
                          "hover:text-slate-200"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-slate-400 ${pathname.includes("smartimages") &&
                                  "text-indigo-300"
                                  }`}
                                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                              />
                              <path
                                className={`fill-current text-slate-700 ${pathname.includes("smartimages") &&
                                  "!text-indigo-600"
                                  }`}
                                d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                              />
                              <path
                                className={`fill-current text-slate-600 ${pathname.includes("smartimages") &&
                                  "text-indigo-500"
                                  }`}
                                d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                              />
                            </svg>
                            <NavLink
                              end
                              to="/admin/smartimages"
                              className={
                                pathname === "/admin/smartimages"
                                  ? "block truncate transition duration-150 text-slate-400"
                                  : "block truncate transition duration-150 text-slate-400 hover:text-slate-200"
                              }
                            >
                              <span className="ml-3 text-sm font-medium duration-200 me lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                smartimages
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup> */}
              {sideBarData.map((res, i) =>
                permissions.includes(res.name) ? (
                  <SidebarMenu
                    key={i}
                    curPathName={res.path}
                    shortPathName={res.shortPathName}
                    SideBarIcon={React.createElement(res.icon, {
                      active: pathname.includes(res.shortPathName),
                    })}
                    menuName={res.name}
                    sidebarExpanded={sidebarExpanded}
                    setSidebarExpanded={setSidebarExpanded}
                  />
                ) : null
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
