import React from "react";

const UserIcon = ({ active }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6516 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6516 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8198 19.6458 19.147 19.4608 19.3832 19.1831C19.6194 18.9053 19.7494 18.5527 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.4733 13.2507 15.292 13.2793 15.116 13.336L14.25 13.619C12.788 14.0963 11.212 14.0963 9.75 13.619L8.884 13.336C8.70847 13.2797 8.52534 13.2507 8.341 13.25H8Z"
          fill={active ? "#a5b4fc" : "#475569"}
        />
      </svg>
    </>
  );
};

export default UserIcon;
