import AdminLogin from "../pages/Admin/AdminLogin";
import Dashbord from "../pages/Admin/Dashbord";
import QuoteManagement from "../pages/Admin/QuoteManagement";
import Contactmanagement from "../pages/Admin/Contactmanagement";
import JobManagement from "../pages/Admin/JobManagement/JobManagement";
import HireDeveloper from "../pages/Admin/HireDeveloper";
import PortfolioManagement from "../pages/Admin/PortfolioManagment/PortfolioManagement";
import SmartImageManagment from "../pages/Admin/smartimagismanagment/SmartImageManagment";
import ServiceManagement from "../pages/Admin/ServiceManagement/ServiceManagement";
import BlogManagement from "../pages/Blogs/BlogManagement";
import AddBlog from "../pages/Blogs/AddBlog";
import UserManagemnet from "../pages/Users/UserManagemnet";
import RoleManagement from "../pages/Roles/RoleManagement";
import EditBlog from "../pages/Blogs/EditBlog";

export default [
  {
    path: "/admin-login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/dashbord",
    element: <Dashbord />,
  },
  {
    path: "/admin/quote-management",
    element: <QuoteManagement />,
  },
  {
    path: "/admin/job-management",
    element: <JobManagement />,
  },
  {
    path: "/admin/contact-management",
    element: <Contactmanagement />,
  },
  {
    path: "/admin/hire-management",
    element: <HireDeveloper />,
  },
  {
    path: "/admin/portfolio-management",
    element: <PortfolioManagement />,
  },
  {
    path: "/admin/smartimages-management",
    element: <SmartImageManagment />,
  },
  {
    path: "/admin/services-management",
    element: <ServiceManagement />,
  },
  {
    path: "/admin/blog-management",
    element: <BlogManagement />,
  },
  {
    path: "/admin/blog-management/add",
    element: <AddBlog />,
  },
  {
    path: "/admin/blog-management/edit",
    element: <EditBlog />,
  },
  {
    path: "/admin/user-management",
    element: <UserManagemnet />,
  },
  {
    path: "/admin/role-management",
    element: <RoleManagement />,
  },
];
