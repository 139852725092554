import { IconButton, Pagination, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const formateDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate =
    date.getDate().toString().padStart(2, "0") +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getFullYear();

  return formattedDate;
};

const RoleTable = ({
  roleData,
  page,
  setPage,
  totalData,
  handleUpdate,
  handleRemove,
  permission,
}) => {
  return (
    <>
      <div className="overflow-x-auto">
        <table className=" w-full table-auto">
          <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
            <tr>
              <th className=" p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Sr.No.
                </div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">Name</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Permissions
                </div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">Date</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          {roleData?.map((data, i) => (
            <tbody key={i}>
              <tr className="border-b-2 hover:bg-slate-50">
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {page > 1 ? `${10 * (page - 1) + (i + 1)}` : i + 1}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {data.name}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    <div className="max-w-[300px] overflow-hidden flex flex-wrap">
                      {data.permissions.map((res, index) => (
                        <span key={index}>{res.module},</span>
                      ))}
                    </div>
                  </div>
                </td>

                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {formateDate(data.createdAt)}
                  </div>
                </td>

                <td className="p-2 whitespace-nowrap">
                  <div className="text-[#1e293b]">
                    <IconButton
                      onClick={() => {
                        handleRemove(data._id);
                      }}
                      disabled={!permission.actions.delete}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleUpdate(data);
                      }}
                      disabled={!permission.actions.update}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <nav className="p-3" aria-label="Page navigation example">
        <Stack spacing={2}>
          <Pagination
            count={totalData}
            page={page}
            onChange={(e, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </nav>
    </>
  );
};

export default RoleTable;
