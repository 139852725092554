import React, { useEffect, useState } from "react";
import Header from "../../../components/AdminPanelComponents/Header";
import Sidebar from "../../../components/AdminPanelComponents/Slidebar";
import { IconButton, Pagination, Button } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../../helper/api";
import { JOBS } from "../../../helper/endpoints";
import { Modal } from "@mui/material";
import Add from "./Add";
import Edit from "./Edit";
import { useSelector } from "react-redux";
import { pagesData } from "../../../common/Data/PagesData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minWidth: "50%",
  // minHeight:"50%",
  backgroundColor: "white",
  boxShadow: 24,
  // padding: "0px 45px 0px 45px",
  borderRadius: 12,
};

const JobManagement = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);

  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [model, setModel] = useState(false);
  const [editmodel, setEditmodel] = useState(false);
  const [myedit, setMyedit] = useState("");
  const [modeldata, setModeldata] = useState([]);
  const [open, setOpen] = useState(false);

  const getContact = async () => {
    api
      .get(`${process.env.REACT_APP_API + JOBS}?page=${page}`)
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setJobData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setOpen(false);
    setModeldata("");
  };

  const handleOpen = (id) => {
    setOpen(true);
    setModeldata(jobData.filter((e) => e._id === id));
  };

  const handelDelete = (id) => {
    api
      .deleteWithToken(`${process.env.REACT_APP_API + JOBS}/${id}`)
      .then((res) => getContact())
      .catch((err) => console.log(err));
  };

  const handelEdit = (id) => {
    setMyedit(...jobData.filter((e) => e._id === id));
    setEditmodel(true);
  };

  useEffect(() => {
    getContact();
  }, [page]);

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.jobManagement
      );
      if (currentData.length !== 0) {
        console.log(currentData);
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className=" bg-white container mx-auto">
          <div className="p-5 w-full">
            <header className="flex justify-between relative py-4 border-b border-slate-100">
              <h2 className="text-sm  font-semibold text-slate-800">
                All Job Cards
              </h2>
              <div>
                <Button
                  onClick={() => setModel(!model)}
                  variant="contained"
                  sx={{ color: "white" }}
                  disabled={!permission.actions.create}
                >
                  Add Blog
                </Button>
              </div>

              <Add model={model} setModel={setModel} getContact={getContact} />

              <Edit
                editmodel={editmodel}
                editdata={myedit}
                setEditmodel={setEditmodel}
                getContact={getContact}
              />
            </header>
            <div className="overflow-x-auto">
              <table className=" w-full table-auto">
                <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className=" p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Job image
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        job title
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Job position
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        experience
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Description
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                {jobData?.map((data) => (
                  <tbody key={data._id}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px]  text-blue">
                          <img
                            className="text-left mb-1 w-[100px] h-[100px]"
                            src={
                              `${process.env.REACT_APP_UPLOADS}` +
                              data.job_image
                            }
                          />
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.job_title}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.position}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.experience}
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() => handleOpen(data._id)}
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            disabled={!permission.actions.view}
                          >
                            View More
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => {
                              handelDelete(data._id);
                            }}
                            disabled={!permission.actions.delete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handelEdit(data._id);
                            }}
                            disabled={!permission.actions.update}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>

          <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                pages={totalPage}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative"
        >
          <table className=" table-auto flex flex-col justify-between order-1 w-full px-2 py-2 bg-white md:order-none md:py-4 md:px-5 lg:px-8 2xl:px-12 rounded-2xl">
            <thead className="font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="text-[14px] font-semibold text-center">
                    Description
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {modeldata &&
                modeldata?.map((data, index) => (
                  <div key={index}>
                    <div className="text-[20px] text-left text-blue  flex flex-col p-3">
                      <div className="grid">
                        {data.description.map((e) => (
                          <p>{e}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </tbody>
          </table>
          {/* </ScrollContainer> */}
        </div>
      </Modal>
    </div>
  );
};

export default JobManagement;
