import BlogIcon from "../Icons/BlogIcon";
import ContactIcon from "../Icons/ContactIcon";
import HireIcon from "../Icons/HireIcon";
import JobIcon from "../Icons/JobIcon";
import PortfolioIcon from "../Icons/PortfolioIcon";
import QuoteIcon from "../Icons/QuoteIcon";
import ServiceIcon from "../Icons/ServiceIcon";
import SmartImagesIcon from "../Icons/SmartImagesIcon";
import UserIcon from "../Icons/UserIcon";
import UserRoleIcon from "../Icons/UserRoleIcon";

export const sideBarData = [
  {
    path: "/admin/quote-management",
    shortPathName: "quote-management",
    name: "Quote Management",
    icon: QuoteIcon,
  },
  {
    path: "/admin/contact-management",
    shortPathName: "contact-management",
    name: "Contact Management",
    icon: ContactIcon,
  },
  {
    path: "/admin/job-management",
    shortPathName: "job-management",
    name: "Job Management",
    icon: JobIcon,
  },
  {
    path: "/admin/user-management",
    shortPathName: "user-management",
    name: "User Management",
    icon: UserIcon,
  },
  {
    path: "/admin/role-management",
    shortPathName: "role-management",
    name: "Role Management",
    icon: UserRoleIcon,
  },
  {
    path: "/admin/hire-management",
    shortPathName: "hire-management",
    name: "Hire Management",
    icon: HireIcon,
  },
  {
    path: "/admin/portfolio-management",
    shortPathName: "portfolio-management",
    name: "Portfolio Management",
    icon: PortfolioIcon,
  },
  {
    path: "/admin/smartimages-management",
    shortPathName: "smartimages-management",
    name: "SmartImages Management",
    icon: SmartImagesIcon,
  },
  {
    path: "/admin/services-management",
    shortPathName: "services-management",
    name: "Services Management",
    icon: ServiceIcon,
  },
  {
    path: "/admin/blog-management",
    shortPathName: "blog-management",
    name: "Blog Management",
    icon: BlogIcon,
  },
];

export const sideBarMenus = [
  "Quote Management",
  "Contact Management",
  "Job Management",
  "User Management",
  "Role Management",
  "Hire Management",
  "Portfolio Management",
  "SmartImages Managem",
  "Services Management",
  "Blog Management",
];
