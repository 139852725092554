export const pagesData = {
  quoteManagement: "Quote Management",
  contactManagement: "Contact Management",
  jobManagement: "Job Management",
  userManagement: "User Management",
  roleManagement: "Role Management",
  hireManagement: "Hire Management",
  portfolioManagement: "Portfolio Management",
  smartImagesManagement: "SmartImages Management",
  servicesManagement: "Services Management",
  blogManagement: "Blog Management",
};
