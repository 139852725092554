import React, { useRef, useState } from "react";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import Header from "../../components/AdminPanelComponents/Header";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../components/SnackBars/SnackBar";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import api from "../../helper/api";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AddBlog = () => {
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const admin = useSelector((state) => state.adminReducer.data);

  const [messageData, setMessageData] = useState({
    messageText: "",
    open: false,
    type: "info",
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [blogData, setBlogData] = useState({
    title: "",
    subTitle: "",
    img: null,
    description: "",
    category: "",
    userRole: "",
  });
  const [blogImage, setBlogImage] = useState({
    img: null,
    imgName: "",
    imgUrl: "",
  });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();

      formData.append("title", blogData.title);
      formData.append("subTitle", blogData.subTitle);
      formData.append("description", blogData.description);
      formData.append("userName", admin.name);
      formData.append("userRole", blogData.userRole);
      formData.append("category", blogData.category);
      if (blogImage.img) {
        formData.append("img", blogImage.img);
      }
      if (admin.img) {
        formData.append("userImg", admin.img);
      }

      const { data } = await api.postWithToken(
        `${process.env.REACT_APP_API}/create/blogs`,
        formData
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        navigate("/admin/blog-management");
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  const handleImageSelect = ({ target }) => {
    const file = target.files[0];
    if (file && file.type.startsWith("image/")) {
      setBlogImage({
        ...blogImage,
        img: file,
        imgName: file.name,
        imgUrl: URL.createObjectURL(file),
      });
    } else {
      setMessageData({
        messageText: "Please Upload Image instead of other files",
        open: true,
        type: "warning",
      });
      target.value = "";
    }
  };
  const handleRemoveImg = () => {
    setBlogImage({
      img: null,
      imgName: "",
      imgUrl: "",
    });
  };
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className=" bg-white container mx-auto">
            <div className="p-5 w-full">
              <header className="flex justify-between relative py-4 border-b border-slate-100">
                <h2 className="text-sm  font-semibold text-slate-800">
                  Update Blog
                </h2>
                <div>
                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={() => navigate("/admin/blog-management")}
                  >
                    View Blog
                  </Button>
                </div>
              </header>
              <div className="w-full">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <label
                        htmlFor="title"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Title
                      </label>
                      <input
                        id="title"
                        name="title"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter Blog Title"
                        value={blogData.title}
                        onChange={({ target }) =>
                          setBlogData({ ...blogData, title: target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="subTitle"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Sub-Title
                      </label>
                      <input
                        id="subTitle"
                        name="subTitle"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter Blog Sub-Title"
                        value={blogData.subTitle}
                        onChange={({ target }) =>
                          setBlogData({ ...blogData, subTitle: target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="Category"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Category
                      </label>
                      <input
                        id="Category"
                        name="category"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter Blog Category"
                        value={blogData.category}
                        onChange={({ target }) =>
                          setBlogData({ ...blogData, category: target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="Category"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        User Role
                      </label>
                      <input
                        id="Category"
                        name="category"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter user role"
                        value={blogData.userRole}
                        onChange={({ target }) =>
                          setBlogData({ ...blogData, userRole: target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="blogImage"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Blog Image
                      </label>
                      <input
                        id="blogImage"
                        name="img"
                        accept="image/*"
                        type="file"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Select Blog Image"
                        onChange={handleImageSelect}
                        required
                      />
                      {blogImage.img ? (
                        <div className="flex justify-between items-center py-2">
                          <div className="flex justify-start items-center space-x-2">
                            <div className="h-[50px] w-[50px] rounded-full overflow-hidden">
                              <img
                                src={blogImage.imgUrl}
                                alt={blogImage.imgName}
                                className="h-full w-full"
                              />
                            </div>
                            <div>{blogImage.imgName}</div>
                          </div>
                          <RemoveCircleOutlineIcon
                            onClick={handleRemoveImg}
                            sx={{ color: "teal", cursor: "pointer" }}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="subTitle"
                        className="block text-sm mb-2.5 font-medium text-gray-900"
                      >
                        Description
                      </label>
                      <Editor
                        tinymceScriptSrc={
                          "/tinymce_6.4.2/tinymce/js/tinymce/tinymce.min.js"
                        }
                        // apiKey="dn9ty5ahjo4xcbrr5svjbsd5dexycqbp5id8k1rn69ha3j7n"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={blogData.description}
                        onEditorChange={(val) =>
                          setBlogData({ ...blogData, description: val })
                        }
                        init={{
                          height: 300,
                          menubar: false,
                          plugins: [
                            "a11ychecker",
                            "advlist",
                            "advcode",
                            "advtable",
                            "autolink",
                            "checklist",
                            "markdown",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "powerpaste",
                            "fullscreen",
                            "formatpainter",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat link image | preview table help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-10 text-center">
                    <button
                      type="submit"
                      className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        open={messageData.open}
        setOpen={(val) =>
          setMessageData({
            messageText: "",
            type: "info",
            open: val,
          })
        }
        text={messageData.messageText}
        type={messageData.type}
      />
    </>
  );
};

export default AddBlog;
