import React, { useEffect, useState } from "react";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import Header from "../../components/AdminPanelComponents/Header";
import { Button } from "@mui/material";
import BlogTable from "./Components/BlogTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";

const BlogManagement = () => {
  const navigate = useNavigate();
  const adminDetails = useSelector((state) => state.adminReducer.data);

  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.blogManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className=" bg-white container mx-auto">
            <div className="p-5 w-full">
              <header className="flex justify-between relative py-4 border-b border-slate-100">
                <h2 className="text-sm  font-semibold text-slate-800">
                  All Blogs
                </h2>
                <div>
                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={() => navigate("/admin/blog-management/add")}
                    disabled={!permission.actions.create}
                  >
                    Add Blog
                  </Button>
                </div>
              </header>
              <BlogTable permission={permission} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogManagement;
