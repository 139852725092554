import React, { useEffect, useState } from "react";
import { IconButton, Pagination, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../../helper/api";
import Swal from "sweetalert2";
import SnackBar from "../../../components/SnackBars/SnackBar";
import { useNavigate } from "react-router-dom";

const formateDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate =
    date.getDate().toString().padStart(2, "0") +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getFullYear();

  return formattedDate;
};

const BlogTable = ({ permission }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const [totalData, setTotalData] = useState(1);
  const [messageData, setMessageData] = useState({
    messageText: "",
    open: false,
    type: "info",
  });

  const getAllBlogData = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API}/get/all/blogs?page=${page}&limit=10`
      );
      if (data.success) {
        setBlogData(data.data);
        setTotalData(data.totalPages);
      } else {
        setBlogData([]);
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      setBlogData([]);
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  const handleRemoveData = async (blogId) => {
    try {
      const { data } = await api.deleteWithToken(
        `${process.env.REACT_APP_API}/delete/blogs?id=${blogId}`
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        getAllBlogData();
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  const askRemoveData = (blogId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You really want to remove this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1565CD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveData(blogId);
      }
    });
  };

  const handleUpdate = (blogData) => {
    navigate("/admin/blog-management/edit", {
      state: blogData,
    });
  };

  useEffect(() => {
    getAllBlogData();
  }, []);
  return (
    <>
      <div className="overflow-x-auto">
        <table className=" w-full table-auto">
          <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
            <tr>
              <th className=" p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Sr.No.
                </div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">Image</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">Title</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Uploaded By
                </div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">Date</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="text-[14px] font-semibold text-left">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          {blogData?.map((data, i) => (
            <tbody key={i}>
              <tr className="border-b-2 hover:bg-slate-50">
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {page > 1 ? `${10 * (page - 1) + (i + 1)}` : i + 1}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px]  text-blue">
                    <img
                      className="text-left mb-1 w-[100px] h-[100px]"
                      src={`${process.env.REACT_APP_UPLOADS}` + data.img}
                    />
                  </div>
                </td>

                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {data.title}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {data.userName}
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-[20px] text-left text-blue">
                    {formateDate(data.createdAt)}
                  </div>
                </td>

                <td className="p-2 whitespace-nowrap">
                  <div className="text-[#1e293b]">
                    <IconButton
                      onClick={() => {
                        askRemoveData(data._id);
                      }}
                      disabled={!permission.actions.delete}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleUpdate(data);
                      }}
                      disabled={!permission.actions.update}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <nav className="p-3" aria-label="Page navigation example">
        <Stack spacing={2}>
          <Pagination
            count={totalData}
            page={page}
            onChange={(e, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </nav>
      <SnackBar
        open={messageData.open}
        setOpen={(val) =>
          setMessageData({
            messageText: "",
            type: "info",
            open: val,
          })
        }
        text={messageData.messageText}
        type={messageData.type}
      />
    </>
  );
};

export default BlogTable;
