import React, { useEffect, useState } from "react";
import Header from "../../components/AdminPanelComponents/Header";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Pagination } from "@mui/material";
import api from "../../helper/api";
import { DEVELOPER } from "../../helper/endpoints";
import { Modal } from "@mui/material";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minWidth: "50%",
  // minHeight:"50%",
  backgroundColor: "white",
  boxShadow: 24,
  // padding: "0px 45px 0px 45px",
  borderRadius: 12,
};

const HireDeveloper = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);
  
  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hireData, setHireData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [developerData, setDeveloperData] = useState([]);
  const [modeltital, setModeltital] = useState("");
  const handleOpen = (data, type) => {
    setOpen(true);

    if (type == "experience") {
      // [data]
      let array = [];
      array.push(data);
      setDeveloperData(array);
    } else {
      setDeveloperData(data);
    }
    setModeltital(type);
  };

  const handleClose = () => {
    setOpen(false);
    setDeveloperData("");
  };

  const getDeveloperData = async () => {
    api
      .getWithToken(
        `${process.env.REACT_APP_API + DEVELOPER + `?page=${page}`}`
      )
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setHireData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const hendelDelete = (id) => {
    api
      .delete(`${process.env.REACT_APP_API + DEVELOPER + `/${id}`}`)
      .then((res) => {
        if (hireData.length === 1) {
          setPage(page - 1);
        }

        getDeveloperData();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDeveloperData();
  }, [page]);
  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.hireManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="container mx-auto bg-white ">
          <div className="w-full p-5">
            <header className="relative py-4 border-b border-slate-100">
              <h2 className="text-sm font-semibold text-slate-800">
                Hire Management
              </h2>
            </header>
            <div className="overflow-x-auto">
              {/* <ScrollContainer className="scroll-container"> */}

              <table className="w-full table-auto ">
                {/* Table header */}
                <thead className="font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Name
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Email
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Phone No.
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Location
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Apply As
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Files
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Company Detail
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Experience
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Qualification
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>

                {/* Table body */}

                {hireData?.map((data) => (
                  <tbody key={data._id}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px]  text-blue">
                          {data.firstName + " " + data.lastName}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.phone}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.location}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.applyAs}
                        </div>
                      </td>

                      <td className="p-2 text-center whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() => handleOpen(data.images, "Files")}
                            className=" text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            disabled={!permission.actions.view}
                          >
                            View More
                          </button>
                        </div>
                      </td>

                      <td className="p-2 text-center whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() =>
                              handleOpen(
                                data.currCompanyDetail,
                                "currCompanyDetail"
                              )
                            }
                            disabled={!permission.actions.view}
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            View More
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() =>
                              handleOpen(data.experience, "experience")
                            }
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            disabled={!permission.actions.view}
                          >
                            View More
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() =>
                              handleOpen(data.qualification, "qualification")
                            }
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            disabled={!permission.actions.view}
                          >
                            View More
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => {
                              hendelDelete(data._id);
                            }}
                            disabled={!permission.actions.delete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                pages={totalPage}
                page={page}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative"
        >
          <table className=" table-auto flex flex-col justify-between order-1 w-full px-2 py-2 bg-white md:order-none md:py-4 md:px-5 lg:px-8 2xl:px-12 rounded-2xl">
            <thead className="font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="text-[14px] font-semibold text-center">
                    {modeltital}
                  </div>
                </th>
              </tr>
            </thead>

            {developerData &&
              developerData?.map((data, index) => (
                <tbody key={index}>
                  {modeltital == "Files" ? (
                    <div className="text-[20px] text-left text-blue flex-col p-3">
                      <div className="">
                        {data.match(/\.(pdf|doc|docx)$/) ? (
                          <a
                            className="text-left mb-1 block w-[20%] h-[20%]"
                            target="_blank"
                            href={process.env.REACT_APP_UPLOADS + data}
                            download
                          >
                            {" "}
                            {data}{" "}
                          </a>
                        ) : (
                          <img src={process.env.REACT_APP_UPLOADS + data} />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-[20px] text-left text-blue flex-col p-3">
                      <div className="grid grid-cols-2 ">
                        <div className="">
                          {Object.keys(data).map((d) => (
                            <p> {d} : </p>
                          ))}
                        </div>
                        <div className="">
                          {Object.values(data).map((d) => (
                            <p>{d}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </tbody>
              ))}
          </table>
          {/* </ScrollContainer> */}
        </div>
      </Modal>
    </div>
  );
};

export default HireDeveloper;
