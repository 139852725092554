import React, { useEffect, useRef, useState } from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../helper/api";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AddUserModal = ({ open, setOpen, setMessageData, callback }) => {
  const imagePicker = useRef(null);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    role_ID: "",
    position: "",
  });
  const [userImage, setUserImage] = useState({
    img: null,
    imgName: "",
    imgUrl: "",
  });
  const [roles, setRoles] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageSelect = ({ target }) => {
    const file = target.files[0];
    if (file && file.type.startsWith("image/")) {
      setUserImage({
        ...userImage,
        img: file,
        imgName: file.name,
        imgUrl: URL.createObjectURL(file),
      });
    } else {
      setMessageData({
        messageText: "Please Upload Image instead of other files",
        open: true,
        type: "warning",
      });
      target.value = "";
    }
  };
  const handleRemoveImg = () => {
    setUserImage({
      img: null,
      imgName: "",
      imgUrl: "",
    });
    imagePicker.value = "";
    imagePicker.files = [];
  };
  const getAllRoles = async () => {
    try {
      const { data } = await api.getWithToken(
        `${process.env.REACT_APP_API}/getrole`
      );
      if (data.success) {
        setRoles(data.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      console.log(error);
      setRoles([]);
    }
  };
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const formData = new FormData();

      formData.append("name", userData.name);
      formData.append("email", userData.email);
      formData.append("password", userData.password);
      formData.append("position", userData.position);
      formData.append("role_ID", userData.role_ID);
      if (userImage.img) {
        formData.append("img", userImage.img);
      }
      const { data } = await api.postWithToken(
        `${process.env.REACT_APP_API}/admin/registration`,
        formData
      );
      if (data.success) {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "success",
        });
        handleClose();
        callback();
      } else {
        setMessageData({
          messageText: data.message,
          open: true,
          type: "warning",
        });
      }
    } catch (error) {
      setMessageData({
        messageText: "Something went wrong",
        open: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
      >
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 h-[80%] overflow-y-auto md:p-10 w-[90%] md:w-[600px]">
          <div className="absolute top-2 right-2">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mt-5 max-h-[600px] overflow-auto">
              <div className="grid grid-cols-2 gap-5">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter user Name"
                    value={userData.name}
                    onChange={({ target }) =>
                      setUserData({ ...userData, name: target.value })
                    }
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter user's mail id"
                    value={userData.email}
                    onChange={({ target }) =>
                      setUserData({ ...userData, email: target.value })
                    }
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="password"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter user's password"
                    value={userData.password}
                    onChange={({ target }) =>
                      setUserData({ ...userData, password: target.value })
                    }
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="userImage"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    User Image
                  </label>
                  <input
                    ref={imagePicker}
                    id="userImage"
                    name="userImage"
                    accept="image/*"
                    type="file"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Select User Image"
                    onChange={handleImageSelect}
                  />
                  {userImage.img ? (
                    <div className="flex justify-between items-center py-2">
                      <div className="flex justify-start items-center space-x-2">
                        <div className="h-[50px] w-[50px] rounded-full overflow-hidden">
                          <img
                            src={userImage.imgUrl}
                            alt={userImage.imgName}
                            className="h-full w-full"
                          />
                        </div>
                        <div>{userImage.imgName}</div>
                      </div>
                      <RemoveCircleOutlineIcon
                        onClick={handleRemoveImg}
                        sx={{ color: "teal", cursor: "pointer" }}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="userRole"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    User Role
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    name="userRole"
                    id="userRole"
                    value={userData.role_ID}
                    onChange={({ target }) =>
                      setUserData({ ...userData, role_ID: target.value })
                    }
                  >
                    {roles.map((res, i) => (
                      <option key={i} value={res._id}>
                        {res.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="position"
                    className="block text-sm mb-2.5 font-medium text-gray-900"
                  >
                    Position
                  </label>
                  <input
                    id="position"
                    name="position"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter user's position"
                    value={userData.position}
                    onChange={({ target }) =>
                      setUserData({ ...userData, position: target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  type="submit"
                  className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddUserModal;
